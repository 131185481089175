import './join-business-group-button.scss';

import {
  ajax
} from 'application/src/js/functions.js';

customElements.define('join-business-group-button', class extends HTMLElement {
  constructor() {
    super();

    this._debugMode = true;
    this._translations = translations.webComponents.joinBusinessGroupButton;

    this._shadowRoot = this.attachShadow({
      mode: 'open'
    });
    const tpl = document.querySelector('#wc-tpl-join-business-group-button');
    if (!tpl || !tpl.content) return; // IE bugfix
    let clonedTpl = tpl.content.cloneNode(true);

    this._shadowRoot.appendChild(clonedTpl);
    this._shadowRoot.querySelector('link').addEventListener('load', e => this.setAttribute('loaded', ''));
  }

  static get observedAttributes() {
    return ['loaded'];
  }

  attributeChangedCallback(name, oldValue, newValue) {
    if (this._inner) this._inner.style.visibility = 'visible';
  }

  connectedCallback() {
    this._urls = {
      dynamic: {
        status: '/dynamicofferRequest/ajaxLoadUserState',
        request: '/dynamicofferRequest/request',
        reject: '/dynamicofferRequest/reject',
      },
      contract: {
        status: '/contractofferRequest/ajaxLoadUserState',
        request: '/contractofferRequest/request',
        reject: '/contractofferRequest/reject',
      }
    };

    this._inner = this._shadowRoot.querySelector('div');
    this._button = this._shadowRoot.querySelector('button');

    if (this._busy) return;
    this._busy = true;

    ajax(this._urls[this._offerType].status, {
      offerid: this._offerId
    }).then(response => {
        this._status = response.data.state;
        this._cuid = response.user.company_user_id;
        this.render();
        this._busy = false;
      })
      .catch(e => {
        console.log(e);
        this._busy = false;
      });
  }

  _addEvents() {
    this._button.addEventListener('click', e => {
      e.preventDefault();

      if (this._status == 'requested') return;

      if (this._status == 'rejected' || this._status == 'none' || this._status == 'canceled') {
        if (this._busy) return;
        this._busy = true;

        ajax(this._urls[this._offerType].request, {
          offerid: this._offerId
        }).then(_ => {
            this._status = 'requested';
            this.render();
            this._busy = false;
          })
          .catch(e => {
            console.log(e);
            this._busy = false;
          });
      }

      if (this._status == 'accepted') {
        if (this._busy) return;
        this._busy = true;

        ajax(this._urls[this._offerType].reject, {
            offerid: this._offerId,
            companyuserid: this._cuid
          })
          .then(_ => {
            this._status = 'rejected';
            this.render();
            this._busy = false;
          })
          .catch(e => {
            console.log(e);
            this._busy = false;
          });
      }
    });
  }

  _remove() {
    this.parentElement.removeChild(this);
  }

  get _offerType() {
    return this.getAttribute('offer-type');
  }

  get _offerId() {
    return parseInt(this.getAttribute('offer-id'));
  }

  get _size() {
    return this.getAttribute('size');
  }

  render() {
    ['rejected', 'none', 'requested', 'accepted', 'disabled', 'canceled'].map(className => this._button.classList.remove(className));

    let classes = [this._status];
    if (this._size) classes.push(this._size);
    if (this._status == 'requested') classes.push('disabled');
    classes.map(className => this._button.classList.add(className));

    this._button.innerHTML = this.template;
    this._addEvents();
  }

  get template() {
    return `
      ${this._status == 'none' || this._status == 'rejected' || this._status == 'canceled' ? `
        <i class="fas fa-sign-in-alt"></i>&nbsp;
        <span>${this._translations.joinGroup}</span>
      ` : ''}

      ${this._status == 'requested' ? `
        <i class="fas fa-hourglass-start"></i>
        <span>${this._translations.requested}</span>
      ` : ''}

      ${this._status == 'accepted' ? `
        <i class="fas fa-sign-out-alt"></i>&nbsp;
        <span>${this._translations.leaveGroup}</span>
      ` : ''}
    `;
  }
});